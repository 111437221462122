import { createContext } from "react";
import { User } from "../models/User.model";

interface AuthContextInterface {
  user: User | null;
  token: string | null;
  isLoading: boolean;
  auth: ({ token, user }: { token: string | null, user: User | null }) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  token: null,
  isLoading: false,
  auth: () => { },
});