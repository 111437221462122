import React from 'react';

export enum ButtonStyle {
    CLASSIC, 
    OUTLINE
}

const BeerButton = ({text, type='button', style = ButtonStyle.CLASSIC, onClick}: {text: string, type?: "button" | "submit" | "reset" | undefined, style?: ButtonStyle, onClick: Function}) => {
    const colors = style === ButtonStyle.CLASSIC ? 
    'bg-light-blue hover:bg-medium-blue text-white' : 
    'border border-medium-blue bg-transparent text-medium-blue hover:bg-medium-blue hover:text-white';

    return (
        <button type={type} onClick={() => onClick()} className={
            colors + ' ease-in duration-150 px-4 py-2 rounded'
        }>{text}</button>
    );
}

export default BeerButton;
